<template>
  <VDialog
    v-model="visibility"
    max-width="600px"
    :transition="$dialogTransition"
  >
    <VCard class="pt-6 pb-4">
      <VCardText class="pb-0">
        <VContainer>
          <VRow class="align-center">
            <VCol cols="12" md="4">
              <img
                :src="require('../../../assets/images/product-desc.png')"
                alt=""
                style="width: 100%; height: 90px"
              >
            </VCol>
            <VCol cols="12" md="8" class="px-0">
              <span class="font-16 text-black">
                {{ item.name }}
              </span>
            </VCol>
            <VCol>
              <p class="text-black">Опис холодильника Опис холодильника Опис холодильника
                Опис холодильника Опис холодильника Опис холодильника</p>
              <div class="d-flex align-center">
                <img :src="require('../../../assets/images/korobka.png')" alt="">
                <span class="text-black d-flex ml-1">
                  {{ item.size_height }}x{{ item.size_width }}x{{ item.size_length }}
                </span>
              </div>
              <div class="d-flex align-center mt-4">
                <img :src="require('../../../assets/images/weight-icon.png')" alt="">
                <span class="text-black d-flex ml-1">{{ item.weight_net }} кг.</span>
              </div>
            </VCol>
          </VRow>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0 container justify-end">
          <VCol cols="12" md="3" sm="12" class="my-0 py-0">
            <VBtn
              dark
              block
              class="w-100 success-bg"
              @click="visibility = false"
            >
              OK
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import dialogMixin from '../../../mixins/dialogMixin';

export default {
  name: 'ClientViewDialog',
  mixins: [dialogMixin],
  props: {
    item: {
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
