<template>
  <VCard
    class="text-center close-block pt-1 px-1 catalog-card"
    ripple
    :color="activeCardID === item.uuid ? '#F9FFE3' : ''"
    max-height="336"
  >
    <div class="close-btn" style="visibility: hidden" @click="onClickDelete">
      <VIcon size="16">mdi-close</VIcon>
    </div>
    <div @click="onEdit">
      <div class="card-image" style="height: 188px;">
        <img
          v-if="!item.path_img"
          :src="require('@/assets/images/product-desc.png')"
          alt=""
          class="object-fit-cover w-100 h-100 br-trsm br-tlsm"
        />

        <img
          v-else-if="item.path_img_thumb"
          :src="item.path_img_thumb"
          class="mb-0 pb-0 br-10 ma-auto w-100 h-100 object-fit-cover"
          v-show="isImgLoaded"
          @load="showImg"
        />
        <img
          v-else
          :src="item.path_img"
          class="mb-0 pb-0 ma-auto w-100 h-100 object-fit-cover br-trsm br-tlsm"
          v-show="isImgLoaded"
          @load="showImg"
        />
        <template v-if="!isImgLoaded && item.path_img">
          <VRow class="fill-height ma-0" align="center" justify="center">
            <VProgressCircular indeterminate color="green "></VProgressCircular>
          </VRow>
        </template>
      </div>
      <div class="product-description">
        <p
          class="
            pa-0
            mx-auto
            mt-4
            mb-0
            text-uppercase
            font-weight-bold font-16
            text-default-gray
            elispsis
            w-80
       
          "
        >
          {{ item.name }}
        </p>

        <VDivider class="mx-3 my-2" />
        <VCardText class="pt-0">
          <VRow no-gutters justify="space-between">
            <VCol cols="6" class="text-left elispsis">
              <span style="color: #3d3d3d">
                <!-- {{  isNaN(amount) ? "--": amount }} -->
                {{ amount }}
              </span>
            </VCol>
            <VCol cols="6" class="text-right elispsis">
              <span style="color: #3d3d3d"> {{ $t('products.meter') }}<sup>3</sup> {{ getItemCapacity }} </span>
            </VCol>
          </VRow>
          <VRow no-gutters justify="space-between">
            <VCol cols="6" class="text-left elispsis">
              <span style="color: #3d3d3d">
                {{ item.bar_code || "--"}}
              </span>
            </VCol>
            <VCol cols="6" class="text-right">
              <span class="font-16 font-weight-bold success--text">
                {{ item.cost || "--" }} ₴
              </span>
            </VCol>
          </VRow>
        </VCardText>
      </div>
    </div>
    <ConfirmDeleteDialog
      :visible="confirmDialog"
      @close="confirmDialog = false"
      @delete="
        $emit('delete', item.uuid);
        confirmDialog = false;
      "
      :uuid="item.uuid"
    />

    <ClientViewDialog :visible="clientViewDialog" @close="clientViewDialog = false" :item="item" />
  </VCard>
</template>

<script>
import ConfirmDeleteDialog from "../../dialog/ConfirmDeleteDialog.vue";
import ClientViewDialog from "./ClientViewDialog.vue";
import user from "../../../mixins/user";
import EventBus from '@/events/EventBus';

export default {
  name: "DescriptionItem",
  mixins: [user],
  components: {
    ConfirmDeleteDialog,
    ClientViewDialog
  },
  data: () => ({
    confirmDialog: false,
    editDialog: false,
    clientViewDialog: false,
    isImgLoaded: false,
    activeID: ""
  }),
  methods: {
    showImg() {
      this.isImgLoaded = true;
    },
    onClickDelete() {
      this.confirmDialog = true;
    },
    onEdit() {
      this.$emit("toggle-row", this.item.uuid);
      EventBus.$emit('on-edit-dialog',{editDialog: this.editDialog = true,item:this.item})
    }
  },
  computed: {
    amount() {
      if('amount' in this.item){
        return `${this.item.amount} ${this.$t('table.piece')}`;
      }
      return "";
    },
    getItemCapacity() {
      const capacity =
        (parseInt(this.item.size_height, 10) *
          parseInt(this.item.size_length, 10) *
          parseInt(this.item.size_width, 10)) /
        1000000000;
      if (capacity) {
        if (capacity < 1) {
          return capacity.toFixed(7);
        } else {
          return capacity;
        }
      } else {
        return "0";
      }
    }
  },
  props: {
    item: {
      required: true
    },
    activeCardID: {
      required: false
    }
  }
};
</script>

<style scoped lang="scss">
table {
  width: 100%;
  tr {
    th,
    td {
      width: 50%;
    }
    th {
      text-align: right;
    }
    td {
      text-align: left;
    }
  }
}
.catalog-card {
  cursor: pointer;
  transition: 0.1s ease;
}
</style>
