<template>
  <tr :class="{ 'active-row': activeCardID == item.uuid }">
    <td class="icon text-center">
      <v-btn
        class="btn-edit"
        color="primary"
        dark
        depressed
        small
        :class="{ sm: !isLarge }"
        @click="onEdit"
      >
        <v-icon>mdi-file-document-edit-outline</v-icon>
      </v-btn>
    </td>
    <td>
      <v-img
        v-if="item.path_img"
        :src="item.path_img"
        @click="showImgDialog(item)"
        class="image"
        v-show="isImgLoaded"
        @load="showImg"
        :class="{ sm: !isLarge }"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              :size="isLarge ? '20' : '10'"
              :width="isLarge ? '2' : '1'"
              color="green lighten-1"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <img
        v-else
        src="@/assets/images/supply-item.png"
        alt=""
        class="image-placeholder"
        :class="{ sm: !isLarge }"
      />
    </td>
    <td>{{ item.name }}</td>
    <td>
      {{ amount || "--" }}
    </td>
    <td>{{ getItemCapacity || "--" }}</td>
    <td :class="item.bar_code ? 'copyTxt' : ''" @click="item.bar_code && copyToClipboard(item.bar_code)">
      {{ item.bar_code || "--" }}
    </td>
    <td>{{ item.cost || "--" }}</td>
    <imgDialog
      :visible="imgDialog"
      @close="imgDialog = false"
      :imgpath="imgpath"
    />
  </tr>
</template>

<script>
import SizeUi from "@/mixins/SizeUi";
import EventBus from "@/events/EventBus";
import imgDialog from "../imgDialog.vue";
import notifications from "@/mixins/notifications";

export default {
  mixins: [SizeUi, notifications],
  components: {
    imgDialog
  },
  props: {
    item: {
      require,
    },
    activeCardID: {
      require,
    },
  },
  data: () => ({
    isImgLoaded: false,
    imgDialog: false,
    imgpath: "",
  }),
  methods: {
    showImg() {
      setTimeout(() => {
        this.isImgLoaded = true;
      }, 1000);
    },
    showImgDialog(product) {
      this.imgpath = product.path_img;
      this.imgDialog = true;
    },
    onEdit() {
      this.$emit("toggle-row", this.item.uuid);
      EventBus.$emit("on-edit-dialog", {
        editDialog: (this.editDialog = true),
        item: this.item,
      });
    },
    async copyToClipboard(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e)
      }
    },
  },
  computed: {
    amount() {
      if ("amount" in this.item) {
        return `${this.item.amount} ${this.$t("table.piece")}`;
      }
      return "";
    },
    getItemCapacity() {
      const capacity =
        (parseInt(this.item.size_height, 10) *
          parseInt(this.item.size_length, 10) *
          parseInt(this.item.size_width, 10)) /
        1000000000;
      if (capacity) {
        if (capacity < 1) {
          return capacity.toFixed(7);
        } else {
          return capacity;
        }
      } else {
        return "0";
      }
    },
  },
};
</script>
